<template>
  <div class="loan-application-loading">
    <Container>
      <Card padding="large" variant="border" class="text-center">
        <Alert type="error" v-if="hasLoanApplicationError">
          <p>
            <span v-html="errorMessage" />
            <router-link
              :to="ROUTES.LOAN_APPLICATION.path"
              :title="$t('ROUTER.LOAN_APPLICATION')"
              >{{ $t('LOAN_APPLICATION') }}</router-link
            >
          </p>
        </Alert>
        <Margins v-else>
          <h1 class="h3 text-normal">
            {{ $t('PLEASE_WAIT_FOR_CHECK_TO_COMPLETE') }}
          </h1>
          <p class="text-small">{{ $t('IT_TAKES_SOME_TIME') }}</p>
          <Loader class="margins__double" />
        </Margins>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  SUBMIT_LOAN_APPLICATION,
  HAS_LOAN_APPLICATION_ERROR,
  SUBMIT_RENEWAL_APPLICATION,
} from '@/types';
import { Container, Card, Margins, Loader, Alert } from '@/components';
import { constants } from '@/mixins';
import { SUPPORT_EMAIL } from '@/constants';

export default {
  name: 'LoanApplicationLoading',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Loader,
    Alert,
  },
  data() {
    return {
      isRenewable: false,
    };
  },
  mounted() {
    this.isRenewableOrder()
      ? this.SUBMIT_RENEWAL_APPLICATION()
      : this.SUBMIT_LOAN_APPLICATION();
  },
  beforeDestroy() {
    if (this.hasLoanApplicationError) {
      this.HAS_LOAN_APPLICATION_ERROR(false);
    }
  },
  methods: {
    ...mapActions([SUBMIT_LOAN_APPLICATION, SUBMIT_RENEWAL_APPLICATION]),
    ...mapMutations([HAS_LOAN_APPLICATION_ERROR]),
    isRenewableOrder() {
      this.isRenewable = localStorage.getItem('isRenewable');
      return this.isRenewable === 'true';
    },
  },
  computed: {
    ...mapState(['hasLoanApplicationError']),
    errorMessage() {
      return this.$t('FORM.ERROR', [
        '<br />',
        `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      ]);
    },
  },
};
</script>
